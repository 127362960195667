<template>
  <a-modal v-model:visible="innerVisible"
           width="600px"
           title="设置角色"
           @ok="onSubmit"
           @cancel="$emit('update:visible', false); checkedKeys.checked = []">
    <a-form :model="form">
      <a-form-item>
        <a-radio-group v-model:value="form.role"
                       @change="changeRole">
          <a-radio value="ROLE_ADMIN">管理员</a-radio>
          <!-- <a-radio value="ROLE_DEPARTMENT">部门管理员</a-radio> -->
          <a-radio value="ROLE_STAFF">普通成员</a-radio>
        </a-radio-group>
      </a-form-item>

      <span class="text-color-999">{{prompt}}</span>

      <!-- HACK: 2022-03-29 隐藏部门管理员 -->
      <!-- <div v-if="form.role === 'ROLE_DEPARTMENT'"
           class="department-select-tree">
        <a-tree v-model:checkedKeys="checkedKeys"
                checkable
                checkStrictly
                :selectable="false"
                :tree-data="treeData"
                :replaceFields="replaceDepartDataKey"
                defaultExpandAll>
          <template #title="{title}">
            <div v-is="'ww-open-data'"
                 class="ww-open-data"
                 type="departmentName"
                 :openid="title"></div>
          </template>
        </a-tree>
      </div> -->
    </a-form>
  </a-modal>
</template>

<script>
import { Radio } from "ant-design-vue";
import { apiStore } from "@/shared/service/api-client";
import staffApi from "@/service/api/staff";

export default {
  name: "MemberModalRole",
  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    // ATree: Tree,
  },
  props: {
    visible: Boolean,
    staffDetail: Object,
    // treeData: Object,
  },
  data() {
    // const replaceDepartDataKey = {
    //   children: "children",
    //   title: "title",
    //   key: "id",
    // };

    const promptList = {
      ROLE_ADMIN: "拥有全部管理权限，可查看所有成员数据",
      ROLE_STAFF: "仅可使用部分功能，仅可查看本人的数据",
      // ROLE_DEPARTMENT: "拥有部门管理权限，可查看指定部门相关的数据",
    };

    return {
      // replaceDepartDataKey,
      form: { role: "" },
      innerVisible: false,
      prompt: "",
      promptList,
      // checkedKeys: [],
    };
  },
  computed: {},
  emits: ["update:visible"],
  watch: {
    visible(newVisible) {
      this.innerVisible = newVisible;
      if (newVisible) {
        this.getStaffInfo();
      }
    },
  },
  created() {
    this.getStaffInfo();
  },

  methods: {
    getStaffInfo() {
      this.form.role = this.staffDetail.roleCode;

      if (this.staffDetail.roleCode === "ROLE_DEPARTMENT") {
        this.form.role = "ROLE_STAFF";
      }

      this.checkedKeys = this.staffDetail.departmentIdList;

      this.prompt = this.promptList[this.form.role];
    },

    changeRole() {
      this.prompt = this.promptList[this.form.role];
    },

    async onSubmit() {
      if (this.form.role === "ROLE_ADMIN") {
        await staffApi.setRoleAdmin({ id: this.staffDetail.id });
      } else if (this.form.role === "ROLE_STAFF") {
        await await staffApi.setRoleStaff({ id: this.staffDetail.id });
      } else if (this.form.role === "ROLE_DEPARTMENT") {
        if (!this.checkedKeys.checked.length) {
          this.$message.error("请选择管理部门");
          return;
        }
        const PARAMS = {
          id: this.staffDetail.id,
          corpId: apiStore.user.corpId,
          departmentIds: this.checkedKeys.checked,
        };
        this.checkedKeys = [];
        await staffApi.setRoleDepartment(PARAMS);
      }

      this.$message.success("设置成功");
      this.$emit("update:visible", false);
      this.$emit("changed");
    },
  },
};
</script>
<style lang="less" scoped>
.ant-form-item {
  margin-bottom: 0;
}

.member-modal__span {
  display: inline-block;
  color: @gray;
  margin-top: 7px;
  margin-bottom: 16px;
}
.ant-radio-wrapper {
  margin-right: 32px;
}
// .department-select-tree {
//   padding: 24px;
//   height: 352px;
//   overflow-y: auto;
//   background-color: @bg-normal;
// }
</style>